import { useEffect } from "react"

const AppRedirect = () => {
  useEffect(() => {
    window &&
      window.location.replace("https://lp.formelskin.de/app-login-via-app")
  }, [])
  return null
}

export default AppRedirect
